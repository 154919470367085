import React from 'react'
import {Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout/layout'
import { Container, StyledButton, Subline, Paragraph, Headline } from '../components/layout/layoutComponents'
import { typo, colors } from '../components/utils'
import TeamMember from '../components/team/teamMember'

const Hero = styled.div`
  padding: 0 35px;
  margin: 0 auto;
  width: 100%;
  height: 435px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.orange};
  @media (min-width: 720px) {
    height: 635px;
  }
  @media (min-width: 1024px) {
    height: 835px;
  }
`

const HeroHeader = styled.h1`
  max-width: 552px;
  font-size: 32px;
  line-height: 46px;
  color: #fff;
  letter-spacing: 6px;
  text-transform: uppercase;
  @media (min-width: 720px) {
    margin-left: 35px;
    font-size: 63px;
    line-height: 78px;
  }
`

const HeroText = styled.p`
  margin: 0;
  font-size: 40px;
  line-height: 53px;
  text-align: center;
  color: #fff;
`
const StyledArticle = styled.article`
  max-width: 1140px;
  margin: 0 auto;
  padding: 100px 35px;
  font-size: 20px;
  h2 {
    font-size: ${typo.fontheadline};
    font-weight: ${typo.bold};
    line-height: ${typo.lineheadline};
    color: ${colors.default};
    text-transform: uppercase;
    margin: 0;
  }
  h3 {
    font-size: ${typo.fontsubline};
    font-weight: ${typo.bold};
    line-height: ${typo.linesubline};
    color: ${colors.orange};
    text-transform: uppercase;
    margin: 0;
  }
`

const TextSection = styled.div`
  max-width: 1090px;
  margin: 60px auto 60px;
  display: flex;
  flex-direction: column;
`
const ArticleText = styled.div`
  margin-top: 66px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  p {
    flex: 40%;
    max-width: 40%;
    font-size: ${typo.fontdefault};
    font-weight: ${typo.regular};
    line-height: ${typo.default};
    color: ${colors.default};
    margin: 0;
    margin-bottom: 30px;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-size: ${typo.fontdefault};
    font-weight: ${typo.regular};
    line-height: ${typo.default};
    color: ${colors.default};
    list-style: none;
    li {
      position: relative;
      margin-bottom: 15px;
      &:before{
        content: '';
        position: absolute;
        top: 8px;
        left: -39px;
        height: 13px;
        width: 13px;
        background-color: ${colors.orange};
      }
    }  
  }
`

const TeamSection = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 55px 0;
  display: flex;
  justify-content: space-between;
`
const MemberContainer  = styled.div`
  max-width: 400px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  img {
    max-width: 400px;
    width: auto;
  }
`

export default function ContactAdressTemplate({ pageContext: { data }}) {

  return (
    <Layout>
    <Hero>
        <HeroHeader>Avnce {data.title}</HeroHeader>
        <HeroText>
        Avance {data.title}<br />
        {data.street}<br />
        {data.city} {data.country}
        </HeroText>
    </Hero>
    <Container>
    <TextSection>   
      <Subline style={{margin: `10px 0`}}>Hier kommt eine Subline</Subline>
      <Paragraph>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem. ipsum dolor sit amet.</Paragraph>
      <StyledButton style={{maxWidth: `130px`, alignSelf: `flex-end` }}><Link to="/contact">Contact</Link></StyledButton>
    </TextSection>
      <TeamSection>
      <div style={{textAlign: `center`, display: `flex`, flexDirection: `column`, margin: `0 auto`}}>
        <Headline>we are moving forward</Headline>
        <Subline>contact person</Subline>
      </div>
      <MemberContainer>
        <TeamMember
          src={data.team[0].image[0].url}
          intro={data.team[0].teamIntro}
          alt={data.team[0].teamame}
          name={data.team[0].teamame}
          role={data.team[0].teamRole}
          mail={data.team[0].email}
          phone={data.team[0].phoneNumber}
        />
      </MemberContainer>
      </TeamSection>
    </Container>
  </Layout>
  )
} 


